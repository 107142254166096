@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include stepsContainer;
  margin-top: 24px;
  margin-bottom: 80px;
}

.iconContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}

.checkIcon {
  width: 56px;
  height: 56px;
}

.title {
  @include h1;
  margin-bottom: 8px;
  text-align: center;

  @include sm {
    margin-bottom: 16px;
  }
}

.subtitle {
  @include subtitle;
  margin-bottom: 24px;
  text-align: center;

  @include sm {
    margin-bottom: 40px;
  }
}

.questionsTitle,
.whatsNextTitle {
  font-weight: $boldFontWeight;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: $black100;
  margin-bottom: 16px;

  @include sm {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.contactsContainer {
  max-width: 864px;
  margin: 0 auto 40px;

  @include sm {
    display: flex;
    justify-content: space-between;
  }
}

.contactItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  background-color: $white;
  box-shadow: 0 16px 48px rgba(36, 52, 91, 0.08);
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  text-transform: none;

  @include sm {
    max-width: 416px;
    width: 45%;
    padding: 58px 16px;
  }
}

.contactItemContainer:not(:last-child) {
  margin-bottom: 16px;

  @include sm {
    margin-bottom: 0;
  }
}

.contactsTitle {
  font-weight: $mediumFontWeight;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  color: $black100;
  margin: 24px 0 4px;
}

.contactsSubtitle {
  font-size: 12px;
  font-weight: $regularFontWeight;
  line-height: 20px;
  color: $black60;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 68px;
}

.primaryButton {
  max-width: 500px;
}

.secondaryButton {
  display: block;
  margin: 32px auto 0;

  @include sm {
    margin: 40px auto 0;
  }
}
