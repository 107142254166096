@import 'src/styles/mixins';
@import 'src/styles/variables';

.loyaltyContainer {
  width: auto;
  background-color: $white;
  box-shadow: $deepBoxShadow;
  padding: 24px;
  border: 1px solid $black10;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  align-items: center;

  .message {
    @include paragraph;
    width: 100%;

    @include sm {
      font-size: 16px;
    }
  }

  .gift {
    width: 52px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}
