@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardSequence {
  @include verticalGap(24px);

  .step {
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: $deepBoxShadow;
    padding: 16px;
    border: 1px solid $black10;
    border-radius: 12px;
    position: relative;

    &.center {
      justify-content: center;
    }

    .stepContent {
      @include verticalGap(8px);

      .title {
        @include body-text('lg', $boldFontWeight);
        color: $black100;

        a {
          color: $purple500;
        }
      }

      .body {
        @include body-text('md', $regularFontWeight);
        color: $black100;
      }
    }
  }

  // Plus icon
  .step:not(:first-child)::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black6;
    background-image: url('/images/arrow-rounded.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $black10;
    border-radius: 50%;
    font-size: 24px;
    font-weight: $blackFontWeight;
  }
}
