@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
  border-radius: 12px;
  margin-bottom: 36px;
  text-align: center;

  @include sm {
    max-width: 650px;
  }

  .header {
    padding: 24px 0;
    position: relative;
    margin: 0 16px;
    cursor: pointer;

    .headerTitle {
      .title {
        @include body-text(lg, 700);
      }

      .consolidationAmount {
        @include body-text(md);
        text-align: left;
        color: $black40;

        .accounts {
          color: $black100;
        }
      }
    }

    .dropdownButton {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
      border-radius: 100%;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 150ms linear;

      &.isToggled {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .content.dropdownOpen {
    height: auto;
  }

  .content {
    @include verticalGap(24px);
    height: 0;
    overflow: hidden;
    transition: height 200ms linear;

    .recommended {
      margin-top: 24px;
      @include body-text(md, 600);
    }

    .subtitle {
      @include body-text(md);
      margin: 12px 8px;
    }

    .actions {
      @include verticalGap(8px);
    }

    .hint {
      @include verticalGap(0);
      @include body-text(md, 400);
      color: $black60;
      font-style: italic;
      text-align: center;
    }

    .separator {
      border-top: 1px solid $black10;
      margin: 0 24px;
    }
  }
}

.content::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
}

.content::-webkit-scrollbar-thumb {
  background-color: #00000022;
  border: 1px solid #ffffff40;
  border-radius: 20px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleButtonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  align-self: stretch;
  margin: 0 auto;
  padding: 0 16px;

  .toggleButton {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: $purple50;
    color: $purple500;
    @include body-text(md, 400);
    cursor: pointer;

    &:first-child {
      border-radius: 12px 0 0 12px;
    }

    &:last-child {
      border-radius: 0 12px 12px 0;
    }

    &.selected {
      background: $purple400;
      color: $white;
      cursor: default;
      pointer-events: none;
    }

    &.disabled {
      background: $black6;
      color: $black40;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.disabled.selected {
      background: $black10;
      color: $black60;
    }
  }
}
