@import 'src/styles/mixins';
@import 'src/styles/variables';

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
  margin-bottom: 8px;
  text-align: left;

  @include sm {
    margin-bottom: 4px;
  }
}

.totalLoanAmountContainer {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.loanBreakdownSeparatorContainer {
  @include verticalGap(8px);
  padding-top: 14px;
  margin-bottom: 6px;
  border-top: 1px solid $black6;
  border-bottom: 1px solid $black6;
}

.termTitle {
  font-size: 16px;
  line-height: 24px;
  color: $black60;
}

.termComparison {
  display: flex;
  gap: 8px;
}

.termValue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.textStrikeThrough {
  font-size: 16px;
  color: $black100;
  line-height: 24px;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}

.separatorContainer {
  height: 1px;
  background-color: $black6;
  margin: 8px 0;
}

.expanderLabel {
  display: flex;
  align-items: center;
}

.loanBreakdownLine {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;

  @include sm {
    margin-bottom: 10px;
  }
}

.expanderContainer {
  text-align: left;
  font-size: 12px;
}

.expanderOpen {
  border-bottom: 1px solid $black6;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.noBorderBottom {
  border-bottom: 0;
}

.tradelineContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .termValue {
    margin-left: auto;
    padding-right: 31px;
  }
}
