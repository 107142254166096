@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardContainer {
  @include verticalGap(8px);
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: $boxShadow;

  ol {
    @include verticalGap(16px);
  }

  ul {
    @include verticalGap(16px);
    list-style-type: disc;
    list-style-position: outside;
    padding-inline-start: 32px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}

.heading {
  @include body-text('lg', $boldFontWeight);
}

.stepBody {
  @include verticalGap(12px);

  .info {
    display: flex;
    justify-content: space-between;

    .name {
      @include body-text('md', $regularFontWeight);
    }

    .value {
      @include body-text('md', $boldFontWeight);
    }
  }
}
