@import 'src/styles/variables';
@import 'src/styles/mixins';

.payoffContent {
  @include verticalGap(16px);
}

.tableAndAssumptions {
  @include verticalGap(24px);
}
