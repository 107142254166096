@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $black100;
  text-align: center;
}

.advantages {
  @include verticalGap(16px);
}

.advantage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  flex-shrink: 0;

  rect {
    fill: $purple50;
  }

  path {
    fill: $purple400;
  }
}

.advantageLabel {
  font-size: 14px;
  line-height: 160%;
  color: $black100;
}

.buttonContainer {
  @include verticalGap(20px);
}

.marginTop {
  margin-top: 16px;
}

.silverLionLogo {
  width: 100% !important;
  height: 48px;
  background: url('/images/silver-lion-logo.png') no-repeat;
  background-position: center;
  background-size: contain;
}
