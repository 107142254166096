@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  text-align: center;
  margin-top: 40px;
}

.title {
  @include h1;
  margin-bottom: 24px;
}

.header {
  font-weight: $boldFontWeight;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $black100;
  margin-bottom: 16px;
}

.subtitle {
  @include subtitle;
  text-align: center;
}

.descriptionContainer:not(:last-child) {
  margin-bottom: 32px;
}

.backButton {
  display: block;
  margin: 40px auto 0;
}

.nextButton {
  display: block;
  margin: 24px auto 0;
}

.clip {
  display: inline-flex;
  align-items: center;
  color: $primaryColor;
  cursor: pointer;

  .icon {
    height: 14px;
    margin-left: 4px;
  }
}
