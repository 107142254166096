@import 'src/styles/variables';
@import 'src/styles/mixins';

.documentsList {
  align-self: baseline;
  list-style: none;
}

.documentListItem {
  font-size: 16px;
  line-height: 28px;
  color: $black100;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: $blue;
    margin-right: 8px;
  }
}

.filesRequirementsList {
  width: 100%;
  list-style: inside;
  font-size: 12px;
  line-height: 20px;
  color: $black60;

  @include sm {
    font-size: 14px;
    line-height: 22px;
  }
}

.uploaded {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
}

ol {
  list-style-position: inside;
}

.buttonContainer {
  @include verticalGap(20px);
}
