@import 'src/styles/variables';
@import 'src/styles/mixins';

.yearsToSaveLabel {
  color: $purple400;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  line-height: 100%;
}

.label {
  @include body-text('lg', 400);
  color: $black100;
  text-align: center;
  display: flex;
  gap: 16px;
}

.buttonContainer {
  @include verticalGap(20px);
}
