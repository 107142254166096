@import 'src/styles/mixins';
@import 'src/styles/variables';

.expanderLabel {
  display: flex;
  align-items: center;
}

.expanderOpen {
  margin-bottom: 8px;
}

.breakdownMainItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 15px;
}

.breakdownMainItemSpacing {
  @include sm {
    margin-bottom: 4px;
  }
}

.breakdownContentSection {
  @include verticalGap(12px);

  @include sm {
    gap: 8px;
  }
}

.breakdownItem {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  margin-top: 8px;

  @include sm {
    padding-right: 31px;
  }
}

.breakdownTitle {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: $black60;
}

.APRComparisonContainer {
  display: flex;
  gap: 8px;
}

.breakdownValue {
  font-size: 16px;
  line-height: 24px;
  color: $black100;
}

.textStrikeThrough {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}

.bold {
  font-weight: $boldFontWeight;
}

.discountDescription {
  @include notification;

  padding: 8px 0 0 32px;
  text-align: left;
  color: $black60;
}
